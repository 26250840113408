import React from 'react';
import { AdminActions } from '../AdminActions/AdminActions';

type Props = {
  children: JSX.Element;
};

const PageWrapper = ({ children }: Props) => (
  <>
    <AdminActions />
    {children}
  </>
);

export { PageWrapper };
