import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';

export type State = {
  state: AuthState;
};

export const initialState: State = {
  state: 'unknown',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authorize: () =>
      ({
        state: 'logged_in',
      } as const),
    deauthorize: () =>
      ({
        state: 'logged_out',
      } as const),
  },
});

export const { deauthorize, authorize } = slice.actions;

export const { reducer } = slice;
