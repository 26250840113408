import { Schema, z } from 'zod';
import * as Sentry from '@sentry/nextjs';

export type ApiResponse<T> = {
  data: T;
};

export const getToken = () =>
  typeof window === 'undefined' ? undefined : localStorage.getItem('token');

export const setToken = (token: string) => localStorage.setItem('token', token);

export const api = {
  async get<T, E = unknown>(
    url: string,
    options?: RequestInit,
    ignoreBadStatusLogging = false
  ): Promise<ApiResponse<T> & E> {
    try {
      const token = getToken();

      const response = await fetch(url, {
        headers: {
          ...(token ? { authorization: `bearer ${token}` } : {}),
        },
        ...options,
      });

      if (response.status > 300 || response.status < 200) {
        const error = await response.json();
        throw Error(error.message, {
          cause: {
            response: {
              status: response.status,
            },
          },
        });
      }

      return response.json();
    } catch (error) {
      if (!ignoreBadStatusLogging) {
        Sentry.captureException(error, {
          data: {
            url,
            options,
          },
        });
      }
      throw error;
    }
  },
  async post<T = any>(url: string, body: any): Promise<T> {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { authorization: `bearer ${token}` } : {}),
        },
      });
      if (response.status > 300 || response.status < 200) {
        const error = await response.json();
        throw Error(error.message, {
          cause: {
            response: {
              status: response.status,
            },
          },
        });
      }
      return response.json();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async put<T = any>(url: string, body: any): Promise<T> {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { authorization: `bearer ${token}` } : {}),
        },
      });
      if (response.status > 300 || response.status < 200) {
        const error = await response.json();
        throw Error(error.message, {
          cause: {
            response: {
              status: response.status,
            },
          },
        });
      }
      return response.json();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async delete<T = any>(url: string): Promise<T> {
    try {
      const token = getToken();
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          ...(token ? { authorization: `bearer ${token}` } : {}),
        },
      });
      if (response.status > 300 || response.status < 200) {
        const error = await response.json();
        throw Error(error.message, {
          cause: {
            response: {
              status: response.status,
            },
          },
        });
      }
      return response.json();
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};

const getApiSchema = <T extends Schema<any>>(schema: T) =>
  z.object({
    data: schema,
  });

export const validateApi =
  <S extends Schema<any>, T>(schema: S) =>
  (data: T) =>
    // getApiSchema(schema).parse(data);
    data;
