import { useDispatch, useSelector } from 'react-redux';
import { CartStorage } from './types';
import { cartSelector } from './selectors';
import { addObjectToCartAction, emptyCartAction, removeObjectFromCartAction } from './slice';

type UseCartItems = {
  cartItemsStorage: CartStorage;
};

const useCartItems = (): UseCartItems => {
  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();

  const cartItemsStorage: CartStorage = {
    fields: { ...cart },

    addObjectToCart(object) {
      dispatch(addObjectToCartAction({ object }));
    },

    removeCartObject(id: string) {
      dispatch(removeObjectFromCartAction({ id }));
    },
    clear() {
      dispatch(emptyCartAction());
    },
  };

  return {
    cartItemsStorage,
  };
};

export { useCartItems };
