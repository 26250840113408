import { Promo, NewPromo } from 'entities/promo';
import { PromoForm } from './types';

export const promoToPromoForm = (promo: Promo): PromoForm => {
  const { slug, id, ...formData } = promo;
  return formData;
};

export const mergePromoAndForm = (promo: Promo, promoForm: NewPromo): Promo => ({
  ...promoForm,
  slug: promo.slug,
  id: promo.id,
});
