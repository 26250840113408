import React, { useLayoutEffect, useState } from 'react';
import { Upload } from 'antd';
import AddIcon from '@material-ui/icons/Add';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { getToken } from '../../api';
import { SavedImage } from '../../image';

import s from './Upload.module.scss';

type ImageValueItem = {
  id: string;
  url: string;
};

const mapUrls = (urls: ImageValueItem[]): UploadFile[] =>
  urls.map(({ url, id }) => ({
    uid: id,
    name: 'image',
    status: 'done',
    url,
  }));

type Props = {
  id?: string;
  value: ImageValueItem[];
  onAdd: (image: SavedImage[]) => void;
  url: string;
  single?: boolean;
  headers?: Record<string, string>;
  sizes: {
    md?: { height?: number; width?: number };
    sm?: { height?: number; width?: number };
  };
  additionalFormats?: string[];
  generatePlaceholder?: boolean;
  limit?: number;
};

const UploadInput = ({
  value,
  onAdd,
  url,
  single = false,
  headers = {},
  sizes,
  additionalFormats = [],
  generatePlaceholder = false,
  limit = 5,
  id,
}: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const token = getToken();

  useLayoutEffect(() => {
    setFileList(mapUrls(value));
  }, []);

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    const newFileList = info.fileList.map(({ url, status, name, uid }) => ({
      uid,
      name,
      status,
      url,
    }));

    setFileList(newFileList);

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      onAdd(info.file.response.data);
    }
  };

  const uploadButton = (
    <div className={s['Upload__add-btn']}>
      <AddIcon />
      <div className="ant-upload-text">Загрузить</div>
    </div>
  );

  const showUploadButton = (!value.length && !loading) || (!single && value.length < limit);

  return (
    <div>
      <div style={{ display: showUploadButton ? 'block' : 'none' }}>
        <Upload
          id={id}
          accept=".jpeg, .jpg"
          showUploadList={false}
          fileList={fileList}
          name="image"
          className={s.Upload}
          listType="picture-card"
          multiple={!single}
          headers={{
            ...headers,
            Authorization: `Bearer ${token}`,
          }}
          data={{
            sizes: JSON.stringify(sizes),
            additionalFormats,
            generatePlaceholder,
          }}
          action={url}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload>
      </div>
    </div>
  );
};

export { UploadInput };
