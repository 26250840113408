import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LensCategory } from 'entities/lensCategory';
import { LensCategoryForm } from './types';
import { lensCategoryToLensCategoryForm } from './helpers';

export type State = LensCategoryForm;

export const initialState: State = {
  title: null,
  hasTiers: false,
  index: null,
};

const slice = createSlice({
  name: 'lensCategoryForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setLensCategory: (state, action: PayloadAction<LensCategory>) =>
      lensCategoryToLensCategoryForm(action.payload),
  },
});

export const { changeField, setLensCategory, clearForm } = slice.actions;

export const { reducer } = slice;
