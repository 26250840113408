import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as auth } from 'entities/auth/slice';

import { reducer as goodsForm } from 'features/goodsForm/slice';
import { reducer as cart } from 'features/cartItems/slice';
import { reducer as subcategoryForm } from 'features/subcategoryForm/slice';
import { reducer as bannerForm } from 'features/bannerForm/slice';
import { reducer as promoForm } from 'features/promoForm/slice';
import { reducer as lensForm } from 'features/lensForm/slice';
import { reducer as lensCategoryForm } from 'features/lensCategoryForm/slice';
import { reducer as lensManufacturerForm } from 'features/lensManufacturerForm/slice';
import { reducer as customerForm } from 'features/customerForm/slice';

const persistConfig = {
  key: 'root',
  version: 2,
  whitelist: ['cart'],
  storage,
};

const rootReducer = combineReducers({
  goodsForm,
  auth,
  cart,
  subcategoryForm,
  bannerForm,
  promoForm,
  lensForm,
  lensCategoryForm,
  lensManufacturerForm,
  customerForm,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
