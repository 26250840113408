import React, { MouseEventHandler } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { Button } from 'antd';
import { numberWithSpaces } from 'shared/format';

import s from './Item.module.scss';

type Props = {
  title: string;
  price: number;
  image: string;
  removeFromCart(): void;
};

const CartItem = ({ title, price, image, removeFromCart }: Props) => {
  const handleRemoveIconClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    removeFromCart();
  };
  return (
    <div className={s.CartItem}>
      <img
        src={image}
        className={s.CartItem__image}
        alt="Товар"
        width="4"
        height="3"
        title={title}
      />
      <div className={s.CartItem__content}>
        <div className={s.CartItem__title}>
          {title}{' '}
          <Button
            ghost
            title="Удалить из корзины"
            className={s['CartItem__remove-icon']}
            aria-label={`Удалить из корзины товар "${title}"`}
            onClick={handleRemoveIconClick}
          >
            <DeleteOutlineIcon />
          </Button>
        </div>
        <div className="CartItem__price"> {numberWithSpaces(price)}&nbsp;&#8381;</div>
      </div>
    </div>
  );
};

export default CartItem;
