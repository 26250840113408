import React, { SVGProps } from 'react';

type Props = SVGProps<any>;

const Rims = (props: Props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463" xmlSpace="preserve">
    <path d="M427.355 72.182c-11.117-1.061-22.206 2.615-30.42 10.083A39.589 39.589 0 0 0 384 111.498a7.5 7.5 0 0 0 15 0 24.557 24.557 0 0 1 8.025-18.134c5.169-4.7 11.884-6.922 18.906-6.249C438.306 88.294 448 99.609 448 112.873v166.625a8.442 8.442 0 0 1-1.638 4.995c-5.475-47.494-45.925-84.495-94.862-84.495-43.168 0-79.732 28.793-91.512 68.181-7.194-7.498-17.301-12.181-28.488-12.181s-21.295 4.682-28.488 12.181c-11.78-39.388-48.344-68.181-91.512-68.181-48.937 0-89.388 37.001-94.862 84.495A8.444 8.444 0 0 1 15 279.498V112.873c0-13.264 9.693-24.579 22.068-25.759 7.026-.672 13.737 1.55 18.906 6.249A24.558 24.558 0 0 1 64 111.498a7.5 7.5 0 0 0 15 0 39.588 39.588 0 0 0-12.935-29.233c-8.214-7.468-19.301-11.141-30.42-10.083C15.657 74.088 0 91.962 0 112.873v166.625c0 10.416 6.813 19.265 16.216 22.339 3.274 49.714 44.756 89.161 95.284 89.161 52.659 0 95.5-42.841 95.5-95.5 0-13.509 10.991-24.5 24.5-24.5s24.5 10.991 24.5 24.5c0 52.659 42.841 95.5 95.5 95.5 50.528 0 92.01-39.447 95.284-89.161 9.402-3.074 16.216-11.923 16.216-22.339V112.873c0-20.911-15.657-38.785-35.645-40.691zM111.5 375.998c-44.388 0-80.5-36.112-80.5-80.5s36.112-80.5 80.5-80.5 80.5 36.112 80.5 80.5-36.112 80.5-80.5 80.5zm240 0c-44.388 0-80.5-36.112-80.5-80.5s36.112-80.5 80.5-80.5 80.5 36.112 80.5 80.5-36.112 80.5-80.5 80.5z" />
  </svg>
);

export default Rims;
