import React, { memo, useRef } from 'react';
import Link from 'next/link';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import useMedia from 'use-media';

import { Home } from '@material-ui/icons';
import { SIZES } from 'shared/sizes';
import { useCartItems } from 'features/cartItems';
import s from './Header.module.scss';
import MainNav from './MainNav/MainNav';

const Header = () => {
  const { cartItemsStorage } = useCartItems();
  const cartGoodsAmount = cartItemsStorage.fields.objects.length;
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMedia(SIZES.MOBILE);
  return (
    <header className={s.Header} ref={ref}>
      <Link href="/" className={s.Header__logo}>
        SI&nbsp;OPTICS
      </Link>
      <MainNav />
      {isMobile && (
        <Link href="/" className={s.Header__home}>
          <Home style={{ fontSize: 26 }} />
        </Link>
      )}
      <Link href="/cart" className={s.Header__cart} title="Корзина" aria-label="Корзина">
        <ShoppingCartIcon className="Header__cart-icon" />
        {cartGoodsAmount > 0 && <div className={s['Header__cart-amount']}>{cartGoodsAmount}</div>}
      </Link>
    </header>
  );
};

const MemoHeader = memo(Header);

export { MemoHeader as Header };
