import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import useMedia from 'use-media';
import { useRouter } from 'next/router';
import Lightbox from 'react-image-lightbox';

import { SIZES } from '../../sizes';
import { s3Path } from '../../url';

import s from './Gallery.module.scss';

type Image = {
  md: string;
};

type Props = {
  images: Image[];
  className?: string;
  title: string;
};
export const Gallery = ({ images, className, title }: Props) => {
  const isMobile = useMedia(SIZES.MOBILE);
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const toggleLightbox = (newSelectedIndex: number) => {
    if (!lightboxIsOpen) {
      router.push(`${window.location.pathname}#modal`);
      setSelectedIndex(newSelectedIndex);
    }
    setLightboxIsOpen(!lightboxIsOpen);
  };

  useEffect(() => {
    if (!lightboxIsOpen) {
      router.replace(router.pathname, window.location.pathname);
    }
  }, [lightboxIsOpen]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as.endsWith('#modal')) {
        setSelectedIndex(0);
        setLightboxIsOpen(true);
      } else {
        setLightboxIsOpen(false);
        setSelectedIndex(0);
      }
      return true;
    });
    return () => {
      router.beforePopState(() => true);
    };
  }, []);

  return (
    <div className={cn(s.Gallery, className)}>
      {!isMobile && (
        <>
          <div className={s.Gallery__images}>
            {images.map((img, j) => (
              <div className={s.Gallery__image} onClick={() => toggleLightbox(j)} key={j}>
                <img src={s3Path(img.md)} title={title} alt={title} />
              </div>
            ))}
          </div>

          {lightboxIsOpen && (
            <Lightbox
              mainSrc={images[selectedIndex].md}
              nextSrc={images[(selectedIndex + 1) % images.length].md}
              prevSrc={images[(selectedIndex + images.length - 1) % images.length].md}
              onCloseRequest={() => toggleLightbox(selectedIndex)}
              onMovePrevRequest={() =>
                setSelectedIndex((selectedIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() => setSelectedIndex((selectedIndex + 1) % images.length)}
            />
          )}
        </>
      )}
      {isMobile && (
        <Carousel swipeable dynamicHeight showThumbs={false} showStatus={false} showArrows={false}>
          {images.map((img, j) => (
            <img key={j} src={s3Path(img.md)} title={title} alt={title} />
          ))}
        </Carousel>
      )}
    </div>
  );
};
