import React from 'react';
import Link from 'next/link';
import { Button } from 'antd';

import { useAuth } from '../../adapters';
import { AdminContent } from '../AdminContent/AdminContent';

import s from './AdminActions.module.scss';

const AdminActions = () => {
  const { logout } = useAuth();
  return (
    <AdminContent>
      <div className={s.AdminActions}>
        <Link href="/admin">
          <Button className={s.AdminActions__toAdminPage}>Панель администратора</Button>
        </Link>
        <Link href="/login">
          <Button className="AdminActions__logout" danger onClick={logout}>
            Выйти из режима администратора
          </Button>
        </Link>
      </div>
    </AdminContent>
  );
};

export { AdminActions };
