export const SIZES = {
  DESKTOP: {
    minWidth: 768,
  },
  MOBILE: {
    maxWidth: 767,
  },
  TABLET: {
    maxWidth: 1024,
    minWidth: 768,
  },
} as const;
