import { nanoid } from 'nanoid';
import { Goods } from 'entities/goods';
import { NewGoods } from 'entities/goods/types';
import { GoodsForm } from './types';

export const goodsToGoodsForm = (goods: Goods): GoodsForm => {
  const { slug, ...other } = goods;
  return {
    ...other,
    image: goods.image.map((img) => ({ ...img, id: nanoid() })),
  };
};

export const mergeGoodsAndForm = (goods: Goods, goodsForm: NewGoods): Goods => ({
  ...goodsForm,
  promoList: goods.promoList ?? [],
  promoIds: goods.promoIds ?? [],
  colorName: goods.colorName ?? undefined,
  slug: goods.slug,
  id: goods.id,
  image: goodsForm.image,
});

export const mapGoodsForm = (form: GoodsForm): NewGoods => ({
  ...form,
  title: form.title ?? '',
  description: form.description ?? '',
  category: form.category ?? 'rims',
  price: form.price ?? 0,
  colorName: form.colorName ?? undefined,
  image: form.image.map(({ id, ...image }) => ({
    sm: image.sm,
    md: image.md,
  })),
});
