import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Loader } from 'shared/components';
import { AdminContent } from '../AdminContent/AdminContent';
import { AdminActions } from '../AdminActions/AdminActions';
import { useAuth } from '../../adapters';

type Props = {
  children: JSX.Element;
};

const AdminPageWrapper = ({ children }: Props) => {
  const router = useRouter();
  const { isViewer, isUnknown } = useAuth();

  useEffect(() => {
    if (isViewer) router.push('/');
  }, [isViewer]);

  if (isUnknown) {
    return (
      <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
        <Loader />
      </div>
    );
  }
  return (
    <AdminContent>
      <>
        <AdminActions />
        {children}
      </>
    </AdminContent>
  );
};

export { AdminPageWrapper };
