import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerForm } from './types';

type State = CustomerForm & {
  error: string | null;
};

export const initialState: State = {
  name: null,
  phone: null,
  comment: null,
  email: null,
  error: null,
};

const slice = createSlice({
  name: 'customerForm',
  initialState,
  reducers: {
    changeField<T extends keyof CustomerForm>(
      state: State,
      action: PayloadAction<{ field: T; value: CustomerForm[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setError: (state: State, action: PayloadAction<{ error: string | null }>) => {
      state.error = action.payload.error;
    },
  },
});

export const { changeField, clearForm, setError } = slice.actions;

export const { reducer } = slice;
