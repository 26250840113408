import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Lens } from 'entities/lens';
import { LensForm } from './types';
import { lensToLensForm } from './helpers';

export type State = LensForm;

export const initialState: State = {
  title: null,
  antiGlare: null,
  manufacturerId: null,
  tier: null,
  diopter: null,
  categoryId: null,
  dirtRepulsion: null,
  endurance: null,
  oldPrice: null,
  price: null,
  waterRepulsion: null,
  description: null,
};

const slice = createSlice({
  name: 'lensForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setLens: (state, action: PayloadAction<Lens>) => lensToLensForm(action.payload),
  },
});

export const { changeField, setLens, clearForm } = slice.actions;

export const { reducer } = slice;
