import React from 'react';
import { Spin } from 'antd';

import s from './Loader.module.scss';

export const Loader = () => (
  <div className={s.loader}>
    <Spin size="large" />
  </div>
);
