import { LensManufacturer, NewLensManufacturer } from 'entities/lensManufacturer';
import { LensManufacturerForm } from './types';

export const lensManufacturerToLensManufacturerForm = (
  lensManufacturer: LensManufacturer
): LensManufacturerForm => {
  const { slug, id, ...formData } = lensManufacturer;
  return formData;
};

export const mergeLensManufacturerAndForm = (
  lensManufacturer: LensManufacturer,
  lensManufacturerForm: NewLensManufacturer
): LensManufacturer => ({
  ...lensManufacturerForm,
  slug: lensManufacturer.slug,
  id: lensManufacturer.id,
});
