import React from 'react';
import { Provider } from 'react-redux';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from '../src/store/store';
import App from '../src/pages/app';

import 'antd/dist/antd.css';
import 'leaflet/dist/leaflet.css';
import 'react-image-lightbox/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/colors.scss';
import '../src/app.scss';
import '../src/mixins.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const persistor = persistStore(store);

const CustomApp = (props: AppProps<{ dehydratedState: unknown }>) => {
  const { pageProps } = props;
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Hydrate state={pageProps.dehydratedState}>
            <App {...props} />
          </Hydrate>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default CustomApp;
