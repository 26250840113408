import { NewSubcategory, Subcategory } from 'entities/subcategory';
import { SubcategoryForm } from './types';

export const subcategoryToSubcategoryForm = (subcategory: Subcategory): SubcategoryForm => {
  const { slug, id, gender, ...formData } = subcategory;
  return formData;
};

export const mergeSubcategoryAndForm = (
  subcategory: Subcategory,
  subcategoryForm: NewSubcategory
): Subcategory => ({
  ...subcategoryForm,
  slug: subcategory.slug,
  goodsIds: subcategory.goodsIds ?? [],
  gender: subcategory.gender,
  id: subcategory.id,
});
