import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addObjectToCart, emptyCart, removeObjectFromCart } from './helpers';
import { CartItems, CartObject } from './types';

export type State = CartItems;

export const initialState: State = {
  objects: [],
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addObjectToCartAction: (state, action: PayloadAction<{ object: CartObject }>) =>
      addObjectToCart(state, action.payload.object),
    removeObjectFromCartAction: (state, action: PayloadAction<{ id: string }>) =>
      removeObjectFromCart(state, action.payload.id),
    emptyCartAction: (state) => emptyCart(state),
  },
});

export const { emptyCartAction, addObjectToCartAction, removeObjectFromCartAction } = slice.actions;

export const { reducer } = slice;
