import { LensCategory } from 'entities/lensCategory';
import { LensCategoryForm, ValidLensCategoryForm } from './types';

export const lensCategoryToLensCategoryForm = (lensCategory: LensCategory): LensCategoryForm => {
  const { id, slug, ...formData } = lensCategory;
  return formData;
};

export const mergeLensCategoryAndForm = (
  lensCategory: LensCategory,
  lensCategoryForm: ValidLensCategoryForm
): LensCategory => ({
  ...lensCategoryForm,
  id: lensCategory.id,
  slug: lensCategory.slug,
});
