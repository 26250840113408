import { UploadListType } from 'antd/lib/upload/interface';
import { UploadFile } from 'antd/es/upload/interface';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import UploadList from 'antd/lib/upload/UploadList';
import React from 'react';

import s from './FileList.module.scss';

type Item = {
  id: string;
  url: string;
};

type FileListProps = {
  onRemove: (id: string) => void;
  files: Item[];
  onReorder?: (tartIndex: number, endIndex?: number) => void;
  listType?: UploadListType;
  name?: string;
};

const FileList = ({
  files,
  onReorder,
  onRemove,
  listType = 'picture-card',
  name = 'image',
}: FileListProps) => {
  const handleRemove = (removed: UploadFile) => {
    onRemove(removed.uid);
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (onReorder) onReorder(source.index, destination?.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className={s.FileList__thumbnails}
            {...provided.droppableProps}
          >
            {files.map(({ id, url }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <UploadList
                      showDownloadIcon={false}
                      showPreviewIcon={false}
                      listType={listType}
                      onRemove={handleRemove}
                      locale={{
                        uploading: 'Загрузка',
                        uploadError: 'Ошибка',
                        downloadFile: 'Скачать',
                        removeFile: 'Удалить',
                        previewFile: 'Показать',
                      }}
                      items={[{ thumbUrl: url, uid: id, url, name }]}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { FileList };
