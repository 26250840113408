import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LensManufacturer } from 'entities/lensManufacturer';
import { LensManufacturerForm } from './types';
import { lensManufacturerToLensManufacturerForm } from './helpers';

export type State = LensManufacturerForm;

export const initialState: State = {
  title: null,
  country: null,
};

const slice = createSlice({
  name: 'lensManufacturerForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setLensManufacturer: (state, action: PayloadAction<LensManufacturer>) =>
      lensManufacturerToLensManufacturerForm(action.payload),
  },
});

export const { changeField, setLensManufacturer, clearForm } = slice.actions;

export const { reducer } = slice;
