import queryString from 'query-string';

export const url = (path: string): string => {
  if (process.env.NODE_ENV !== 'production') return `http://localhost:3000${path}`;
  return `${process.env.SITE_URL}${path}`;
};
export const getQuery = (asPath: string) => queryString.parse(asPath.split('?')[1]);

export const getPath = (asPath: string): string => asPath.split('?')[0];

function isAbsoluteURL(url: string) {
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//');
}
const getPathFromUrl = (url: string): string => (isAbsoluteURL(url) ? new URL(url).pathname : url);

export const s3Path = (url: string, isAbsolute?: boolean): string =>
  process.env.NODE_ENV === 'production'
    ? `${isAbsolute ? process.env.SITE_URL : ''}/s3${getPathFromUrl(url)}`
    : url;
