import React, { SVGProps } from 'react';

type Props = SVGProps<any>;

const Lens = (props: Props) => (
  <svg
    {...props}
    style={{ maxWidth: 20 }}
    xmlns="http://www.w3.org/2000/svg"
    height="100"
    width="100"
    fill="#000000"
    viewBox="0 0 32 32"
    fillRule="evenodd"
  >
    <path d="M17.073 26.84c-7.109.373-12.666-3.98-13.056-11.423-.192-3.668.885-5.738 3.183-6.891 1.861-.934 3.885-1.217 8.802-1.475l.034-.002c7.824-.41 11.497 1.433 11.795 7.12.35 6.68-4.479 12.342-10.758 12.671h0zm.105 1.997c7.406-.388 13.057-7.013 12.65-14.773-.372-7.091-5.116-9.472-13.897-9.012l-.034.002c-5.219.274-7.386.577-9.594 1.685-3.043 1.526-4.516 4.355-4.283 8.783.451 8.612 7.002 13.743 15.158 13.316h0zM6.994 15.922l-.054-.457c-.072-.755-.082-1.55.01-2.286.09-.716.27-1.305.545-1.722.322-.49 1.637-.916 3.54-1.174l2.73-.242.901-.034.337-.006-.007-1-.356.007-.926.035-2.815.25c-2.211.3-3.691.78-4.241 1.615-.372.565-.595 1.293-.702 2.148a11.59 11.59 0 0 0-.013 2.505l.062.517.988-.155h0z" />
  </svg>
);

export default Lens;
