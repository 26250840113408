import { nanoid } from 'nanoid';

import { Goods } from 'entities/goods';
import { Lens } from 'entities/lens';
import { CartItems, CartObject } from './types';

const createCertificate = (sum: number): CartObject => ({
  title: 'Сертификат',
  id: `cert_${nanoid()}`,
  type: 'certificate',
  image: '/static/gallery/certificate.jpg',
  price: sum,
});

const addObjectToCart = (cart: CartItems, object: CartObject): CartItems => ({
  ...cart,
  objects: [...cart.objects, object],
});

const removeObjectFromCart = (cart: CartItems, id: string): CartItems => ({
  ...cart,
  objects: cart.objects.filter((item) => item.id !== id),
});

const emptyCart = (cart: CartItems): CartItems => ({
  ...cart,
  objects: [],
});

const getCartTotal = (objects: CartObject[]): number =>
  objects.reduce((acc, item) => acc + item.price, 0);

const hasCartObject = (goodsList: CartObject[], id: string) =>
  goodsList.some((object) => object.id === id);

const goodsToCartObject = (goods: Goods): CartObject => ({
  id: goods.slug,
  image: goods.image[0].sm,
  price: goods.price,
  type: 'image',
  title: goods.title,
  href: `${process.env.SITE_URL}/${goods.category}/${goods.slug}`,
});

const lensToCartObject = (lens: Lens): CartObject => ({
  id: lens.id,
  image: '/static/gallery/lens.jpg',
  price: lens.price,
  type: 'image',
  title: lens.title,
});

export {
  createCertificate,
  goodsToCartObject,
  addObjectToCart,
  removeObjectFromCart,
  emptyCart,
  getCartTotal,
  hasCartObject,
  lensToCartObject,
};
