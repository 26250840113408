import { Banner, NewBanner } from 'entities/banner';
import { BannerForm } from './types';

export const bannerToBannerForm = (banner: Banner): BannerForm => {
  const { id, ...formData } = banner;
  return formData;
};

export const mergeBannerAndForm = (banner: Banner, bannerForm: NewBanner): Banner => ({
  ...bannerForm,
  id: banner.id,
});
