import React, { memo, useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';

import { api } from 'shared/api';
import { useAuth } from 'entities/auth';
import { Header } from '../widgets/header';
import { Footer } from '../widgets/footer';

const CustomApp = (props: AppProps) => {
  const { Component, pageProps, router } = props;

  const { deauthorize, authorize } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.asPath]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    api.get('/api/check-auth', {}, true).then(authorize).catch(deauthorize);
  }, []);

  return (
    <>
      <Head>
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Header />
      <main className="Layout">
        <Component {...pageProps} />
      </main>
      <Footer />
      <div className="overlay" />
    </>
  );
};

export default memo(CustomApp);
