import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Subcategory } from 'entities/subcategory';
import { SubcategoryForm } from './types';
import { subcategoryToSubcategoryForm } from './helpers';

export type State = SubcategoryForm;

export const initialState: State = {
  title: null,
  image: null,
  category: null,
};

const slice = createSlice({
  name: 'subcategoryForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setSubcategory: (state, action: PayloadAction<Subcategory>) =>
      subcategoryToSubcategoryForm(action.payload),
  },
});

export const { changeField, setSubcategory, clearForm } = slice.actions;

export const { reducer } = slice;
