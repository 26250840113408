import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Banner } from 'entities/banner';
import { BannerForm } from './types';
import { bannerToBannerForm } from './helpers';

export type State = BannerForm;

export const initialState: State = {
  title: null,
  images: null,
  description: null,
  linkText: null,
  href: null,
  video: null,
  isPromoBanner: null,
};

const slice = createSlice({
  name: 'bannerForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setBanner: (state, action: PayloadAction<Banner>) => bannerToBannerForm(action.payload),
  },
});

export const { changeField, setBanner, clearForm } = slice.actions;

export const { reducer } = slice;
