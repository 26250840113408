import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Promo } from 'entities/promo';
import { promoToPromoForm } from './helpers';
import { PromoForm } from './types';

export type State = PromoForm;

export const initialState: State = {
  title: null,
  images: null,
  description: null,
  linkText: null,
  goodsIds: [],
  discount: 0,
  fixedPrice: null,
};

const slice = createSlice({
  name: 'promoForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    clearForm: () => initialState,
    setPromo: (state, action: PayloadAction<Promo>) => promoToPromoForm(action.payload),
  },
});

export const { changeField, setPromo, clearForm } = slice.actions;

export const { reducer } = slice;
