import React from 'react';
import { useAuth } from '../../adapters';

type Props = {
  children: JSX.Element;
};
const AdminContent = ({ children }: Props) => {
  const { isAdmin } = useAuth();
  return isAdmin ? children : null;
};

export { AdminContent };
