import React from 'react';
import { useCartItems } from '../../adapters';

import Item from '../Item/Item';

import s from './CartItems.module.scss';

const CartItems = () => {
  const {
    cartItemsStorage: {
      removeCartObject,
      fields: { objects },
    },
  } = useCartItems();
  const objectsTiles = objects.map(({ price, id, image, title }) => (
    <Item
      key={id}
      price={price}
      title={title}
      image={image}
      removeFromCart={() => removeCartObject(id)}
    />
  ));

  return <div className={s.CartItems}>{objectsTiles}</div>;
};

export { CartItems };
