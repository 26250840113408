import React from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { Room } from '@material-ui/icons';
import useMedia from 'use-media';
import { SIZES } from 'shared/sizes';
import s from './MainNav.module.scss';
import Lens from './icons/Lens';
import Rims from './icons/Rims';
import Sun from './icons/Sun';
import Try from './icons/Try';

const MainNav = () => {
  const isMobile = useMedia(SIZES.MOBILE);
  return (
    <nav className={cn(s.MainNav)}>
      <ul className={s.MainNav__list}>
        <li className={s.MainNav__item}>
          <Link href="/rims" className={s.MainNav__link}>
            {isMobile && <Rims className={s['MainNav__item-icon']} />}
            Оправы
          </Link>
        </li>
        <li className={s.MainNav__item}>
          <Link href="/sun" className={s.MainNav__link}>
            {isMobile && <Sun className={s['MainNav__item-icon']} />}
            Солнце
          </Link>
        </li>
        <li className={s.MainNav__item}>
          <Link href="/lens" className={s.MainNav__link}>
            {isMobile && <Lens className={s['MainNav__item-icon']} />}
            Линзы
          </Link>
        </li>
        <li className={s.MainNav__item}>
          <Link href="/shops" className={s.MainNav__link}>
            {isMobile && <Room className={s['MainNav__item-icon']} style={{ maxWidth: 20 }} />}
            Магазин
          </Link>
        </li>
        <li className={s.MainNav__item}>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/try" className={cn(s.MainNav__link, s.MainNav__link_special)}>
            {isMobile && <Try className={s['MainNav__item-icon']} />}
            {isMobile ? 'Примерка' : 'Примерка онлайн'}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MainNav;
