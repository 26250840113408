import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { Goods } from 'entities/goods';
import { GoodsForm, Image } from './types';
import { goodsToGoodsForm } from './helpers';

export type State = GoodsForm;

export const initialState: State = {
  title: '',
  description: null,
  category: null,
  price: null,
  image: [],
  color: null,
  priority: 2,
  subcategoryIds: [],
  inStock: true,
  oldPrice: null,
};

const slice = createSlice({
  name: 'goodsForm',
  initialState,
  reducers: {
    changeField<T extends keyof State>(
      state: State,
      action: PayloadAction<{ field: T; value: State[T] }>
    ) {
      state[action.payload.field] = action.payload.value;
    },
    addImage: (state, action: PayloadAction<Image>) => {
      state.image.push({ ...action.payload, id: nanoid() });
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.image = state.image.filter((item) => item.id !== action.payload);
    },
    clearForm: () => initialState,
    setGoods: (state, action: PayloadAction<Goods>) => goodsToGoodsForm(action.payload),
  },
});

export const { changeField, setGoods, clearForm, addImage, removeImage } = slice.actions;

export const { reducer } = slice;
