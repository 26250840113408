import React, { ChangeEventHandler, useId } from 'react';

import s from './StarRatingSelect.module.scss';

type Props = {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: 1 | 2 | 3 | undefined;
};

const StarRatingSelect = ({ name, onChange, value }: Props) => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();
  return (
    <fieldset className={s.rating}>
      <input
        id={id1}
        type="radio"
        name={name}
        onChange={onChange}
        value="1"
        checked={value === 1}
      />
      <label htmlFor={id1} className={s.star} />
      <input
        id={id2}
        type="radio"
        name={name}
        onChange={onChange}
        value="2"
        checked={value === 2}
      />
      <label htmlFor={id2} className={s.star} />
      <input
        id={id3}
        type="radio"
        name={name}
        onChange={onChange}
        value="3"
        checked={value === 3}
      />
      <label htmlFor={id3} className={s.star} />
    </fieldset>
  );
};

export { StarRatingSelect };
