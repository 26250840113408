import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from 'shared/api';
import { authorize as authorizeAction, deauthorize as deauthorizeAction } from './slice';
import { authState as authStateSelector } from './selectors';

const useAuth = () => {
  const dispatch = useDispatch();

  const authState = useSelector(authStateSelector);

  const isAdmin = authState === 'logged_in';

  const isUnknown = authState === 'unknown';

  const isViewer = authState === 'logged_out';

  const authorize = useCallback(() => {
    dispatch(authorizeAction());
  }, [dispatch]);

  const deauthorize = useCallback(() => {
    dispatch(deauthorizeAction());
  }, [dispatch]);

  const login = useCallback(
    (token: string) => {
      setToken(token);
      authorize();
    },
    [authorize]
  );

  const logout = useCallback(() => {
    setToken('');
    deauthorize();
  }, [deauthorize]);

  return {
    isAdmin,
    isViewer,
    isUnknown,
    authorize,
    deauthorize,
    login,
    logout,
  };
};

export { useAuth };
