import React, { useLayoutEffect, useState } from 'react';
import { Upload } from 'antd';
import AddIcon from '@material-ui/icons/Add';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { getToken } from 'shared/api';

import s from './UploadVideo.module.scss';

type VideoValueItem = {
  id: string;
  url: string;
};

const mapUrls = ({ url, id }: VideoValueItem): UploadFile => ({
  uid: id,
  name: 'video',
  status: 'done',
  url,
});

type Props = {
  value: VideoValueItem | null;
  onAdd: (video: string) => void;
  url: string;
  single?: boolean;
  headers?: Record<string, string>;
};

const UploadVideoInput = ({ value, onAdd, url, single = false, headers = {} }: Props) => {
  const [file, setFile] = useState<UploadFile | null>(null);
  const [loading, setLoading] = useState(false);

  const token = getToken();

  useLayoutEffect(() => {
    setFile(value ? mapUrls(value) : null);
  }, []);

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    const newFileList = info.fileList.map(({ url, status, name, uid }) => ({
      uid,
      name,
      status,
      url,
    }));

    setFile(newFileList[0] ?? null);

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      onAdd(info.file.response);
    }
  };

  const uploadButton = (
    <div className={s['Upload__add-btn']}>
      <AddIcon />
      <div className="ant-upload-text">Загрузить</div>
    </div>
  );

  const showUploadButton = !value && !loading;

  return (
    <div>
      <div style={{ display: showUploadButton ? 'block' : 'none' }}>
        <Upload
          accept=".mp4"
          showUploadList={false}
          fileList={file ? [file] : []}
          name="video"
          className={s.Upload}
          listType="picture-card"
          multiple={!single}
          headers={{
            ...headers,
            Authorization: `Bearer ${token}`,
          }}
          action={url}
          onChange={handleChange}
        >
          {uploadButton}
        </Upload>
      </div>
    </div>
  );
};

export { UploadVideoInput };
