import { Lens, NewLens } from 'entities/lens';
import { LensForm } from './types';

export const lensToLensForm = (lens: Lens): LensForm => {
  const { id, slug, ...formData } = lens;
  return formData;
};

export const mergeLensAndForm = (lens: Lens, lensForm: NewLens): Lens => ({
  ...lensForm,
  slug: lens.slug,
  id: lens.id,
});
